import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import AppsIcons from "../components/elements/AppsIcons";
import Anchor from "../components/elements/Anchor";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import Icon from "../components/elements/Icon";
import { constructActiveDoc } from "../helpers";

const chargespotApp = ({ data }) => {
  const { settings, page } = data;
  const {
    title,
    description,
    image,
    image_mobile: imageMobile,
    middle_image: middleImage,
    informations,
    link,
    link_label: linkLabel,
    list,
  } = page.data;

  const iconList = list.map((item) => (
    <div className="icon-text-list mb-3" key={item.text.text}>
      <Icon icon={item.icon} />
      <RichText
        render={item.text.richText}
        serializeHyperlink={AnchorPrismic}
      />
    </div>
  ));

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <HeaderBackground
          title={title}
          description={description}
          image={image}
          imageMobile={imageMobile}
          headerSize="2"
        />
        <Container fluid>
          <Row className="align-items-lg-center py-5">
            <Col sm={6} xxl={4} className="col-xxxl-3 offset-xxxl-1">
              {informations && (
                <RichText
                  render={informations.richText}
                  serializeHyperlink={AnchorPrismic}
                />
              )}
              <AppsIcons settings={settings} size="large" className="my-5" />
              {link.url && linkLabel && (
                <Anchor
                  href={link.url}
                  target={link.target}
                  className="d-inline-block mb-5 fw-bold"
                >
                  {linkLabel}
                </Anchor>
              )}
            </Col>
            {middleImage.gatsbyImageData && (
              <Col sm={6} xxl={4} className="col-xxxl-4 text-center">
                <GatsbyImage
                  image={middleImage.gatsbyImageData}
                  alt={middleImage.alt || ""}
                  className="m-5"
                />
              </Col>
            )}
            <Col xxl={4} className="col-xxxl-3">
              {iconList}
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query chargespotAppQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicChargespotApp(lang: { eq: $lang }) {
      ...prismicChargespotAppFragment
    }
  }
`;

export default withPrismicPreview(chargespotApp);
